import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {BaseFranchiseEntity} from 'app/blocks/model/base-franchise-entity.model';
import {IProduct} from 'app/blocks/model/product.model';

export class ProductPrice {
    product?: IProduct;
    discount?: number;

    constructor(productPrice?) {
        if (productPrice) {
            this.product = productPrice.product;
            this.discount = productPrice.discount;
        }
    }
}

export interface IPromotion extends IBaseEntity {
    id?: number;
    name?: string;
    description?: string;
    fromDate?: string;
    toDate?: string;
    buyQuantity?: number;
    getQuantity?: number;
    productPrices?: ProductPrice[];
    productPricesGet?: ProductPrice[];
}

export class Promotion extends BaseFranchiseEntity implements IPromotion {
    public id?: number;
    public name?: string;
    public description?: string;
    public hasApplied?: boolean;
    public fromDate?: string;
    public toDate?: string;
    public buyQuantity?: number;
    public getQuantity?: number;
    public productPrices?: ProductPrice[];
    public productPricesGet?: ProductPrice[];

    constructor(promotion?) {
        super();
        if (promotion) {
            this.id = promotion.id;
            this.name = promotion.name;
            this.description = promotion.description;
            this.hasApplied = promotion.hasApplied;
            this.fromDate = promotion.fromDate;
            this.toDate = promotion.toDate;
            this.buyQuantity = promotion.buyQuantity;
            this.getQuantity = promotion.getQuantity;
            this.productPrices = promotion.productPrices;
            this.productPricesGet = promotion.productPricesGet;
            this.editable = promotion.editable;
        } else {
            this.productPrices = [];
            this.productPricesGet = [];
        }
    }

    get discriminator(): any {
        return this.name;
    }

    clone(): Promotion {
        return new Promotion(this);
    }
}

export class PromotionPopup {
    public id?: number;
    public name?: string;
    public description?: string;
    public isSelected?: boolean;
}
